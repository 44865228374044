import { Item, JobSearch } from '@borg/types';
import { isArray, isBoolean, isObject, objectKeys } from '@borg/utils';

export function isItem(value: unknown): value is Item<string | number> {
  return (
    isObject(value) &&
    'name' in value &&
    typeof value.name === 'string' &&
    'id' in value &&
    (typeof value.id === 'string' || typeof value.id == 'number')
  );
}

export function getSearchQueryObject(params: JobSearch['params']) {
  const allParams = {
    ...params?.static,
    ...params?.dynamic,
    page: undefined,
  };

  return objectKeys(allParams).reduce<Record<string, null | string | number | (string | number)[]>>(
    (acc, key) => {
      const value = allParams[key];

      if (isBoolean(value)) {
        if (value) {
          acc[key] = null;
        }
      } else if (isItem(value)) {
        // Special case for locations - use name instead of id
        if (key === 'locations') {
          acc[key] = value.name;
        } else {
          acc[key] = value.id;
        }
      } else if (isArray(value) && value.length) {
        // Special case for locations array - use names instead of ids
        if (key === 'locations') {
          acc[key] = value.map((v) => (isItem(v) ? v.name : v));
        } else {
          acc[key] = value.map((v) => (isItem(v) ? v.id : v));
        }
      }

      return acc;
    },
    {},
  );
}
