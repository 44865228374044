<script setup lang="ts">
  const config = useRuntimeConfig();

  useHead({
    link: [
      {
        rel: 'stylesheet',
        href: 'https://cdn.jsdelivr.net/npm/@almacareer/cookie-consent-manager@3/CookieConsentManager.min.css',
      },
    ],
    script: [
      {
        type: 'text/javascript',
        src: 'https://cdn.jsdelivr.net/npm/@almacareer/cookie-consent-manager@3/init.js',
        defer: true,
      },
    ],
  });

  onMounted(() => {
    if (typeof initCookieConsentManager === 'function') {
      // initCookieConsentManager(config.public.project.domain);
    }
  });
</script>

<template>
  <span v-if="false" />
</template>
