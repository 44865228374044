import revive_payload_client_g9mFmAc6kz from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.54.0_iored_q4wnf4kdw6i3bzt3cxgcqblula/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_uQQgQ4Puc8 from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.54.0_iored_q4wnf4kdw6i3bzt3cxgcqblula/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_XagUwjUkpP from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.54.0_iored_q4wnf4kdw6i3bzt3cxgcqblula/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_laIjUVFmfj from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.54.0_iored_q4wnf4kdw6i3bzt3cxgcqblula/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_FDWFZFrC3Q from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.54.0_iored_q4wnf4kdw6i3bzt3cxgcqblula/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_VWHUHcCxjE from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.54.0_iored_q4wnf4kdw6i3bzt3cxgcqblula/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_LQC9K3kUq4 from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.54.0_iored_q4wnf4kdw6i3bzt3cxgcqblula/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_MsEwoR3YOl from "/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.5_rollup@4.28.1_typescript@5.4.5_vue@3.5.13_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/apps/site/.nuxt/components.plugin.mjs";
import prefetch_client_GARooIB0ZX from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.54.0_iored_q4wnf4kdw6i3bzt3cxgcqblula/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_HlYL3akL4v from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.2.0_magicast@0.3.5_rollup@4.28.1_vue@3.5.13_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import _20_env_612nqPD8GK from "/app/apps/site/plugins/20.env.ts";
import _30_user_jwt_mvBwInWRkI from "/app/apps/site/plugins/30.user-jwt.ts";
import _40_sentry_client_HjOVFNcJVj from "/app/apps/site/plugins/40.sentry.client.ts";
import _50_masonry_wall_FORjha14i9 from "/app/apps/site/plugins/50.masonry-wall.ts";
import _70_google_tag_manger_client_Xc6xP22Ep5 from "/app/apps/site/plugins/70.google-tag-manger.client.ts";
import _80_meta_client_KYedthzPAW from "/app/apps/site/plugins/80.meta.client.ts";
import error_handler_kEP5FliEXj from "/app/apps/site/plugins/error-handler.ts";
export default [
  revive_payload_client_g9mFmAc6kz,
  unhead_uQQgQ4Puc8,
  router_XagUwjUkpP,
  payload_client_laIjUVFmfj,
  navigation_repaint_client_FDWFZFrC3Q,
  check_outdated_build_client_VWHUHcCxjE,
  chunk_reload_client_LQC9K3kUq4,
  plugin_vue3_MsEwoR3YOl,
  components_plugin_KR1HBZs4kY,
  prefetch_client_GARooIB0ZX,
  i18n_HlYL3akL4v,
  _20_env_612nqPD8GK,
  _30_user_jwt_mvBwInWRkI,
  _40_sentry_client_HjOVFNcJVj,
  _50_masonry_wall_FORjha14i9,
  _70_google_tag_manger_client_Xc6xP22Ep5,
  _80_meta_client_KYedthzPAW,
  error_handler_kEP5FliEXj
]